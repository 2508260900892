<template>
  <header class="navbar navbar-static-top navbar-top-area" v-bind:class="navbarTopArea">
    <nav class="navbar navbar-default navbar-content">
      <a href="/" class="navbar-brand navbar-brand-area"></a>
      <ul class="nav navbar-nav">
        <li>
          <a data-locale="technology" href="javascript:void(0)">技术</a>
          <div class="navbar-dropdown-menu">
            <ul class="navbar-menu-list">
              <li class="navbar-menu-list-title"><a href="javascript:void(0)"
                                                    data-locale="faceRecognition">人脸识别</a></li>
              <li>
                <router-link to="face_detection.html">人脸检测</router-link>
              </li>
              <li>
                <router-link to="face_crux.html">人脸关键点</router-link>
              </li>
              <li>
                <router-link to="face_compare.html">人脸对比</router-link>
              </li>
              <li>
                <router-link to="face_attributes.html">人脸属性</router-link>
              </li>
              <li>
                <router-link to="face_retrieve.html">人脸搜索</router-link>
              </li>
            </ul>
            <ul class="navbar-menu-list">
              <li class="navbar-menu-list-title"><a href="javascript:void(0)"
                                                    data-locale="videoStructuring">视频结构化</a></li>
              <li>
                <router-link to="discernment_clothing.html">衣着识别</router-link>
              </li>
              <li>
                <router-link to="discernment_gender.html">性别识别</router-link>
              </li>
              <li>
                <router-link to="discernment_age.html">年龄识别</router-link>
              </li>
              <li>
                <router-link to="discernment_licence_plate.html">车牌识别</router-link>
              </li>
              <li>
                <router-link to="discernment_car.html">车辆类型</router-link>
              </li>
            </ul>
            <ul class="navbar-menu-list">
              <li class="navbar-menu-list-title"><a href="javascript:void(0)"
                                                    data-locale="cloudIDRecognition">云身份认证</a></li>
              <li>
                <router-link to="discernment_id_card.html">身份证云解码</router-link>
              </li>
              <li>
                <router-link to="discernment_passport.html">护照、港澳通行证识别</router-link>
              </li>
              <li>
                <router-link to="discernment_cid.html">CID公民网络身份识别</router-link>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <a data-locale="product" href="javascript:void(0)">产品</a>
          <div class="navbar-dropdown-menu dropdown-menu-sm">
            <ul class="navbar-menu-list">
              <li class="navbar-menu-list-title">
                <router-link to="product_api.html">WEB API</router-link>
              </li>
              <li class="navbar-menu-list-title">
                <router-link to="product_sdk.html">SDK</router-link>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <router-link to="business.html">业务</router-link>
        </li>
<!--        <li>-->
<!--          <router-link to="price.html">价格</router-link>-->
<!--        </li>-->
        <li>
          <router-link to="openapi.html">文档</router-link>
        </li>
        <li>
          <a data-locale="experience" href="javascript:void(0)">体验</a>
          <div class="navbar-dropdown-menu dropdown-menu-demo">
            <ul class="navbar-menu-list">
              <li class="navbar-menu-list-title"><a href="javascript:void(0)"
                                                    data-locale="faceRecognition">人脸识别</a></li>
              <li>
                <router-link to="face_detection.html#demo-content">人脸检测</router-link>
              </li>
              <li>
                <router-link to="face_crux.html#demo-content">人脸关键点</router-link>
              </li>
              <li>
                <router-link to="face_compare.html#demo-content">人脸对比</router-link>
              </li>
              <li>
                <router-link to="face_attributes.html#demo-content">人脸属性</router-link>
              </li>
            </ul>
            <!-- <ul class="navbar-menu-list">
              <li class="navbar-menu-list-title"><a href="javascript:void(0)" data-locale="videoStructuring">视频结构化</a></li>
              <li><a href="discernment_clothing.html#demo-content" data-locale="clothingRecognition">衣着识别</a></li>
              <li><a href="discernment_license.html#demo-content" data-locale="plateNumberRecognition">车牌识别</a></li>
              <li><a href="discernment_car.html#demo-content" data-locale="vehicleRecognition">车辆类型</a></li>
            </ul> -->
          </div>
        </li>
        <li>
          <a data-locale="about" href="https://www.yzfuture.cn/views/about/index.html"
             target="_blank">关于我们</a>
        </li>
      </ul>

      <ul class="nav navbar-nav navbar-right" v-if="!userName">
        <li class="hidden"><a id="i18n-btn" href="javascript:void(0)"><i class="fa fa-futbol-o"></i> 中文/English</a>
        </li>
        <li class="navbar-login-item">
          <a href="javascript:void(0)" class="js-jump-session-btn" @click="toLogin"
             data-locale="signIn">登录</a>
        </li>
        <li class="navbar-login-item">
          <a href="javascript:void(0)" @click="toSignup">
                        <span class="navbar-item-box js-jump-registration-btn"
                              data-locale="signUp">注册</span>
          </a>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right" v-else>
        <li class="navbar-login-item">
          <a href="javascript:void(0)" class="navbar-user-name">
            <span data-locale="hello">您好</span>，
            <span id="navbar-user-name">{{ userName }}</span></a>
        </li>
        <li class="navbar-login-item">
          <a href="javascript:void(0)" @click="toConsole"><span>控制台</span></a>
        </li>
        <li class="navbar-login-item">
          <a href="javascript:void(0)" @click="toLogout" class="js-navbar-logout-btn">
            <span class="logout-btn" data-locale="signOut">退出</span></a>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import axios from 'axios';
import {goToConsole, goToLoginCenter, goToSignup} from "@/utils/util";
import {checkAuthorization, removeAuthorization, removeAuthorizationStore} from "@/utils/request";
import {doLogout} from "@/services/user";

export default {
  name: "Header",
  mounted: function () {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  created: function () {
    this.getUserInfo();
  },
  data: function () {
    return {
      loginUrl: "http://192.168.0.243:20000",
      consoleUrl: "http://192.168.0.243:9001",
      navbarTopArea: "navbar-top-area",
      userName: "",
    }
  },
  methods: {
    toLogin: function () {
      goToLoginCenter()
    },
    toConsole: function () {
      goToConsole()
    },
    toSignup: function () {
      goToSignup()
    },
    toLogout: function () {
      doLogout().then(() => {
        localStorage.removeItem(process.env.VUE_APP_USER_KEY)
        removeAuthorization()
        removeAuthorizationStore()
        this.userName = null
      })
      let self = this;
      let token = sessionStorage.getItem("Authorization");
      if (!token) {
        self.toLogin();
        return
      }
    },
    getUserInfo: function () {
      if (checkAuthorization()) {
        let userInfo = localStorage.getItem(process.env.VUE_APP_USER_KEY)
        if (userInfo !== undefined) {
          let juser = JSON.parse(userInfo);
          this.userName = juser ? juser.name : ""
        }
      }
    },
    getQueryVariable: function (variable) {
      let query = window.location.search.substring(1);
      let vars = query.split("&");
      for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return (false);
    },
    handleScroll: function (e) {
      var scrollTop = document.documentElement.scrollTop ||
          document.body.scrollTop;
      //设置导航栏添加背景
      if (scrollTop > 30) {
        this.navbarTopArea = "navbar-top-area-inverse";
      } else {
        this.navbarTopArea = "";
      }
    }
  },
}
</script>

<style >

</style>
