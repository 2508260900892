import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Index from '../views/Index.vue'
import {loginGuard,scrollToPageTop} from './guard'
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index,
        meta: {scrollToTop: true}
    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/business.html',
        name: 'Business',
        component: () => import(/* webpackChunkName: "about" */ '../views/Business.vue')
    },
    {
        path: '/product_sdk.html',
        name: 'Sdk',
        component: () => import(/* webpackChunkName: "about" */ '../views/product/Sdk.vue')
    },
    {
        path: '/product_api.html',
        name: 'Api',
        component: () => import(/* webpackChunkName: "about" */ '../views/product/Api.vue')
    },
    {
        path: '/discernment_age.html',
        name: 'DiscernmentAge',
        component: () => import(/* webpackChunkName: "about" */ '../views/technical/DiscernmentAge.vue')
    },
    {
        path: '/discernment_car.html',
        name: 'DiscernmentCar',
        component: () => import(/* webpackChunkName: "about" */ '../views/technical/DiscernmentCar.vue')
    },
    {
        path: '/discernment_clothing.html',
        name: 'DiscernmentClothing',
        component: () => import(/* webpackChunkName: "about" */ '../views/technical/DiscernmentClothing.vue')
    },
    {
        path: '/discernment_cid.html',
        name: 'DiscernmentCid',
        component: () => import(/* webpackChunkName: "about" */ '../views/technical/DiscernmentCid.vue')
    },
    {
        path: '/discernment_gender.html',
        name: 'DiscernmentGender',
        component: () => import(/* webpackChunkName: "about" */ '../views/technical/DiscernmentGender.vue')
    },
    {
        path: '/discernment_id_card.html',
        name: 'DiscernmentIdCard',
        component: () => import(/* webpackChunkName: "about" */ '../views/technical/DiscernmentIdCard.vue')
    },
    {
        path: '/discernment_licence_plate.html',
        name: 'DiscernmentLicencePlate',
        component: () => import(/* webpackChunkName: "about" */ '../views/technical/DiscernmentLicencePlate.vue')
    },
    {
        path: '/discernment_passport.html',
        name: 'DiscernmentPassport',
        component: () => import(/* webpackChunkName: "about" */ '../views/technical/DiscernmentPassport.vue')
    },
    {
        path: '/face_attributes.html',
        name: 'FaceAttributes',
        component: () => import(/* webpackChunkName: "about" */ '../views/technical/FaceAttributes.vue')
        , meta: {scrollToTop: true}
    },
    {
        path: '/face_compare.html',
        name: 'FaceCompare',
        component: () => import(/* webpackChunkName: "about" */ '../views/technical/FaceCompare.vue')
        , meta: {scrollToTop: true}
    },
    {
        path: '/face_crux.html',
        name: 'FaceCrux',
        component: () => import(/* webpackChunkName: "about" */ '../views/technical/FaceCrux.vue')
        , meta: {scrollToTop: true}
    },
    {
        path: '/face_detection.html',
        name: 'FaceDetection',
        component: () => import(/* webpackChunkName: "about" */ '../views/technical/FaceDetection.vue')
        , meta: {scrollToTop: true}
    },
    {
        path: '/face_retrieve.html',
        name: 'FaceRetrieve',
        component: () => import(/* webpackChunkName: "about" */ '../views/technical/FaceRetrieve.vue')
        , meta: {scrollToTop: true}
    },
    {
        path: '/price.html',
        name: 'Price',
        component: () => import(/* webpackChunkName: "about" */ '../views/Price.vue')
        , meta: {scrollToTop: true}
    },
    {
        path: '/documents.html',
        name: 'Documents',
        component: () => import(/* webpackChunkName: "about" */ '../views/documents.vue')
        , meta: {scrollToTop: true}
    },
    {
        path: '/doc.html',
        name: 'Documents',
        component: () => import(/* webpackChunkName: "about" */ '../views/doc.vue')
        , meta: {scrollToTop: true}
    },
    {
        path: '/openapi.html',
        name: 'WEBAPI',
        component: () => import(/* webpackChunkName: "about" */ '../views/docs/DocOpenApi.vue')
        , meta: {scrollToTop: true}
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
router.beforeEach(loginGuard)
router.afterEach(scrollToPageTop);
export default router
