<template>
    <footer class="page-footer">
        <div class="page-content">
            <div class="footer-content-area footer-service-area">
                <div class="footer-title" data-locale="coreTechnology">核心技术</div>
                <ul class="footer-list inline-footer-list">
                    <li><a href="face_detection.html" data-locale="faceCheck">人脸检测</a></li>
                    <li><a href="face_crux.html" data-locale="faceLinchpin">人脸关键点</a></li>
                    <li><a href="face_compare.html" data-locale="faceContrast">人脸对比</a></li>
                    <li><a href="face_attributes.html" data-locale="faceAttribute">人脸属性</a></li>
                    <li><a href="face_retrieve.html" data-locale="faceSearch">人脸搜索</a></li>
                    <li><a href="discernment_age.html" data-locale="ageRecognition">年龄识别</a></li>
                    <li><a href="discernment_clothing.html" data-locale="clothingRecognition">衣着识别</a></li>
                    <li><a href="discernment_gender.html" data-locale="genderRecognition">性别识别</a></li>
                    <li><a href="discernment_licence_plate.html" data-locale="plateNumberRecognition">车牌识别</a></li>
                    <li><a href="discernment_car.html" data-locale="vehicleRecognition">车辆类型</a></li>
                    <li><a href="discernment_id_card.html" data-locale="IDCloudDecipher">身份证云解码</a></li>
                    <li><a href="discernment_cid.html" data-locale="CIDRecognition">CID公民网络身份识别</a></li>
                    <li class="special-footer-list-item"><a href="discernment_passport.html"
                                                            data-locale="passportRecognition">护照、港澳通行证识别</a></li>

                </ul>
            </div>
            <div class="footer-content-area footer-produce-area">
                <div class="footer-title" data-locale="product">产品</div>
                <ul class="footer-list">
                    <li><a href="product_api.html">WEB API</a></li>
                    <li><a href="product_sdk.html">SDK</a></li>
                </ul>
            </div>
            <div class="footer-content-area footer-business-area">
                <div class="footer-title" data-locale="business">业务</div>
                <ul class="footer-list">
                    <li><a href="business.html" data-locale="buildingSystem">搭建智能云系统</a></li>
                    <li><a href="business.html#operation" data-locale="systemOperation">智能云系统运维</a></li>
                </ul>
            </div>
            <div class="footer-content-area footer-sale-area">
                <div class="footer-title" data-locale="beforeAndAfterSales">售前售后</div>
                <ul class="footer-list">
                    <li><span data-locale="preSalePhone">咨询热线(一)</span>：028-85038030</li>
                    <li><span data-locale="preSalePhone">咨询热线(二)</span>：028-82880293</li>
                    <li><a href="openapi.html" data-locale="accessGuide">接入指南</a></li>
                </ul>
            </div>
            <div class="footer-content-area footer-about-area">
                <div class="footer-title" data-locale="about">关于我们</div>
                <ul class="footer-list">
                    <li><a href="https://www.yzfuture.cn" target="_blank" data-locale="companyProfile">公司简介</a></li>
                    <!-- <li><a href="about_us_index.html#laboratory" data-locale="customerCase">客户案例</li> -->
                    <!-- <li><a href="about_us_index.html#connection" data-locale="contactUs">联系我们</li> -->
                </ul>
            </div>

            <div class="clear-line"></div>
            <p class="footer-message" data-locale="WebsiteCopyright">
            <span id="copyright-text" style="margin-right: 10px;">{{copyright}}</span>
                <span class="copyright-item"><a href="http://beian.miit.gov.cn"  style="text-decoration: none;"
                                                target="_blank">蜀ICP备19014280号</a></span>
              <span style="margin-left: 5px">
                <a href="https://beian.mps.gov.cn/#/query/webSearch?code=51019002006052" rel="noreferrer" style="text-decoration: none;" target="_blank"><img style="
                display: inline-block;
                width: 15px;
                height: 15px;
                margin-right: 2px;
                margin-bottom: 5px;" src="../assets/beian.png"/>川公网安备51019002006052</a>
              </span>
                <!-- 版权所有 | 法律声明 | 隐私保护 -->
            </p>
            <p class="footer-message" style="font-size: 14px;">
                <span style="margin-right: 20px"><i class="fa fa-fax"
                                                    style="margin-right: 10px"></i> 028 82880293</span>
                <span><i class="fa fa-map-marker" style="margin-right: 10px"></i>成都市高新区天府大道中段530号东方希望天祥广场A栋3005</span>
            </p>
        </div>
    </footer>
</template>
<script>
    export default {
        name: "Footer",
        data: function () {
            return {
                copyright:"",
            }
        },
        created: function(){
            this.copyright = this.getYear();
        },
        methods: {
            getYear: function () {
                return   "成都鱼住未来科技有限公司版权所有 ©2019 - " + new Date().getFullYear()
            }
        }
    }
</script>
