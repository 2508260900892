<template>
    <div>
        <h1 class="home-title">鱼住云人工智能开放平台</h1>

        <div class="video-area">
            <!-- <img id="video-image" src="video_bg.png" /> -->
            <video id="video-object" loop v-on:contextmenu="cancelVideoMenu" v-on:canplaythrough="onVideoLoaded" muted="muted" src="../assets/images/home_bg.mp4"></video>
        </div>

        <main>
            <div id="face-discernment-area" class="service-list-area">
                <div class="service-list-content">
                    <div class="service-introduce page-introduce">
                        <p class="brief-introduce">高效的人脸快速定位和自动跟踪能力</p>
                        <p class="detailed-introduce">
                            准确识别多种人脸属性大类如年龄、性别、微笑程度、头部姿态、眼睛状态、情绪、颜值、视线 、嘴部状态、皮肤状态。针对生物特征智能识别，判断生物行为，如张嘴、眨眼、点头等动作，智能区分照片、3D建模、活体生物。
                        </p>
                    </div>
                    <ul class="service-list">
                        <li>
                            <i class="service-icon face-test-icon"></i>
                            <span class="service-name">人脸检测</span>
                        </li>
                        <li>
                            <i class="service-icon face-crux-icon"></i>
                            <span class="service-name">人脸关键点</span>
                        </li>
                        <li>
                            <i class="service-icon face-contrast-icon"></i>
                            <span class="service-name">人脸对比</span>
                        </li>

                        <li>
                            <i class="service-icon face-attribute-icon"></i>
                            <span class="service-name">人脸属性</span>
                        </li>
                        <li>
                            <i class="service-icon face-search-icon"></i>
                            <span class="service-name">人脸搜索</span>
                        </li>
                    </ul>
                </div>
            </div>


            <div id="video-analysis-area" class="service-list-area service-list-inverse">
                <div class="service-list-content">
                    <div class="service-introduce">
                        <p class="brief-introduce">为目标提供更深层次的结构化解析</p>
                        <p class="detailed-introduce">
                            视频结构化分析服务器对于视频图像中尤其关注的人、车、非机动车类别的目标提供更深层次的结构化解析。对于视频图像中的人物，可进行人脸检测、人脸识别从而分析出人物的具体身份，并可提供行人的各种结构化特征属性信息，包括衣着和装饰物特征。
                        </p>
                    </div>
                    <ul class="service-list">
                        <li>
                            <i class="service-icon discernment-license-icon"></i>
                            <span class="service-name">车牌识别</span>
                        </li>
                        <li>
                            <i class="service-icon discernment-car-icon"></i>
                            <span class="service-name">车辆类型</span>
                        </li>
                        <li>
                            <i class="service-icon discernment-age-icon"></i>
                            <span class="service-name">年龄识别</span>
                        </li>
                        <li>
                            <i class="service-icon discernment-gender-icon"></i>
                            <span class="service-name">性别识别</span>
                        </li>
                        <li>
                            <i class="service-icon discernment-clothing-icon"></i>
                            <span class="service-name">衣着识别</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div id="card-analysis-area" class="service-list-area">
                <div class="service-list-content">
                    <div class="service-introduce">
                        <p class="brief-introduce">身份证云解析和证件真伪辨别</p>
                        <p class="detailed-introduce">
                            通过云解码技术对身份证、护照、港澳通行证进行识别，与专业信息库进行对比，鉴别信息真伪。通过云解码技术对身份证、护照、港澳通行证进行识别，与专业信息库进行对比，实现人证合一。
                        </p>
                    </div>
                    <ul class="service-list">
                        <li>
                            <i class="service-icon id-card-icon"></i>
                            <span class="service-name">身份证云解码</span>
                        </li>
                        <li class="card-analysis-icon-area">
                            <i class=""></i>
                        </li>
                        <li>
                            <i class="service-icon pass-check-icon"></i>
                            <span class="service-name">护照、港澳通行证识别</span>
                        </li>
                    </ul>


                </div>
            </div>

            <ul class="server-state-list">
                <li class="server-state-list-item">
                    <a class="anchor-link" href="javascript:void(0)" data-href="#face-discernment-area"></a>
                    <span>人脸识别</span>
                </li>
                <li class="server-state-list-item">
                    <a class="anchor-link" href="javascript:void(0)" data-href="#video-analysis-area"></a>
                    <span>视频结构化</span>
                </li>
                <li class="server-state-list-item">
                    <a class="anchor-link" href="javascript:void(0)" data-href="#card-analysis-area"></a>
                    <span>云身份认证</span>
                </li>
            </ul>
        </main>
    </div>
</template>

<script>
    export default {
        name: "Index",
        data:()=> {
            return {
                scrollDate:{}
            }
        },
        created: function(){
            var self = this;
            let options = {
                videoBox:   '#video-object',
                videoImage: "#video-image",
                anchorBox:  ".anchor-link",

                faceAreaSelector:  "#face-discernment-area",
                videoAreaSelector: "#video-analysis-area",
                cardAreaSelector:  "#card-analysis-area",
                faceIntroduceSelector:  "#face-discernment-area .service-introduce",
                videoIntroduceSelector: "#video-analysis-area .service-introduce",
                cardIntroduceSelector:  "#card-analysis-area .service-introduce",
                serverStateSelector:    ".server-state-list",
                pageFooterSelector:     ".page-footer",
            };

            self.$videoBox   = $(options.videoBox);
            self.$videoImage = $(options.videoImage);
            self.$anchorBox  = $(options.anchorBox);

            self.$faceAreaSelector  = $(options.faceAreaSelector);
            self.$videoAreaSelector = $(options.videoAreaSelector);
            self.$cardAreaSelector  = $(options.cardAreaSelector);
            self.$faceIntroduceSelector  = $(options.faceIntroduceSelector);
            self.$videoIntroduceSelector = $(options.videoIntroduceSelector);
            self.$cardIntroduceSelector  = $(options.cardIntroduceSelector);
            self.$serverStateSelector    = $(options.serverStateSelector);
            self.$pageFooterSelector     = $(options.pageFooterSelector);
        },
        methods:{
            onVideoLoaded: function(){
                document.getElementById("video-object").play();
            },
            cancelVideoMenu: function(){
                return false;
            },
            getScrollMessage: function(){
                var self = this;
                self.scrollDate = {
                    windowHeight: $(window).height(),  //可视窗口的大小
                    faceAreaTop: self.$faceAreaSelector.offset().top,  //人脸识别服务到可视窗口顶部的距离
                    faceAnimateTop: self.$faceIntroduceSelector.offset().top,   //人脸识别服务介绍区域到可视窗口顶部的距离
                    videoAreaTop: self.$videoAreaSelector.offset().top,  //视频结构化服务到可视窗口顶部的距离
                    videoAnimateTop: self.$videoIntroduceSelector.offset().top,   //视频结构化服务介绍区域到可视窗口顶部的距离
                    cardAreaTop: self.$cardAreaSelector.offset().top,  //证件解码服务到可视窗口顶部的距离
                    cardAnimateTop: self.$cardIntroduceSelector.offset().top,   //证件解码服务介绍区域到可视窗口顶部的距离
                    pageFooterTop: self.$pageFooterSelector.offset().top   //页脚区域到可视窗口顶部的距离
                }
            },
            setSrollAnimate: function(){
                var self = this;

                var scrollTop = $(window).scrollTop();//获取现在滚动条到顶部的距离
                /*
                  设置介绍部分的滑出动画，其中第一个介绍不需要动画
                */
                if(scrollTop > self.scrollDate.videoAnimateTop - self.scrollDate.windowHeight + 60 && scrollTop < self.scrollDate.videoAnimateTop + 200){
                    if(!self.$videoIntroduceSelector.hasClass("service-animate-add")){
                        self.$videoIntroduceSelector.addClass("service-animate-add");
                    }
                }

                if(scrollTop > self.scrollDate.cardAnimateTop - self.scrollDate.windowHeight + 60 && scrollTop < self.scrollDate.cardAnimateTop + 200){
                    if(!self.$cardIntroduceSelector.hasClass("service-animate-add")){
                        self.$cardIntroduceSelector.addClass("service-animate-add");
                    }
                }

                /*
                 *设置右侧浮标的样式，处于白色背景时设置为默认黑色样式，处于深色背景时设置为白色样式
                */
                //处于人脸识别上面部分
                if(scrollTop < self.scrollDate.faceAreaTop - self.scrollDate.windowHeight * 0.5 + 20){
                    self.$serverStateSelector.hide();
                }
                //处于人脸识别部分
                else if(scrollTop < self.scrollDate.videoAreaTop - self.scrollDate.windowHeight * 0.5 + 20){
                    self.$serverStateSelector.show();
                    self.$serverStateSelector.removeClass("server-state-list-inverse");
                    $(self.options.serverStateSelector + " li.active").removeClass("active");
                    $(self.options.serverStateSelector + " li:nth-child(1)").addClass("active");
                }
                //处于视频结构化部分
                else if(scrollTop < self.scrollDate.cardAreaTop - self.scrollDate.windowHeight * 0.5 + 20){
                    self.$serverStateSelector.show();
                    self.$serverStateSelector.addClass("server-state-list-inverse");
                    $(self.options.serverStateSelector + " li.active").removeClass("active");
                    $(self.options.serverStateSelector + " li:nth-child(2)").addClass("active");
                }
                //处于云身份认证部分
                else if(scrollTop < self.scrollDate.pageFooterTop - self.scrollDate.windowHeight * 0.5 + 20){
                    self.$serverStateSelector.show();
                    self.$serverStateSelector.removeClass("server-state-list-inverse");
                    $(self.options.serverStateSelector + " li.active").removeClass("active");
                    $(self.options.serverStateSelector + " li:nth-child(3)").addClass("active");
                }
                //处于页脚部分
                else {
                    self.$serverStateSelector.hide();
                }
            }
        }
    }
</script>

<style scoped>
    @import "../assets/css/index.css";
</style>