export function goToLoginCenter() {
    let redirect = encodeURIComponent(process.env.VUE_APP_BASE_URL)
    window.location.replace(process.env.VUE_APP_LOGIN_URL + "?redirect=" + redirect)
}

export function goToHome() {
    window.location.replace(process.env.VUE_APP_BASE_URL)
}

export function goToConsole() {
    let redirect = encodeURIComponent(process.env.VUE_APP_CONSOLE_URL)
    window.location.replace(process.env.VUE_APP_LOGIN_URL + "?redirect=" + redirect)
}
export function goToSignup() {
    let redirect = encodeURIComponent(process.env.VUE_APP_BASE_URL)
    window.location.replace(process.env.VUE_APP_LOGIN_URL + "?alt=signup&redirect=" + redirect)
}
