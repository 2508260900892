import {request, METHOD, setAuthorizationStore, setAuthorization} from "../utils/request";
import {AUTH} from '../services/api';
import {getUserInfo} from "../services/user";
import {goToHome, goToLoginCenter} from "../utils/util";

export const scrollToPageTop = (to, from, next) => {
    window.scrollTo(0, 0);
}
export const loginGuard = (to, from, next) => {
    if (to && to.query && to.query._at) {
        request(AUTH, METHOD.GET, {}, {
            headers: {
                'Auth-ticket': to.query._at,
                'Content-Type': 'application/json'
            }
        }).then(res => {
            let data = res.data;
            if (data) {
                let token = data.collection[0].token;
                let expireAt = data.collection[0].expireAt;
                if (token && expireAt > 0) {
                    setAuthorization({token:token,expireAt: new Date(new Date().getTime() + expireAt)})
                    setAuthorizationStore(token)

                    getUserInfo().then((res) => {
                        if (res.data) {
                            let info = res.data.collection[0]
                            localStorage.setItem(process.env.VUE_APP_USER_KEY, JSON.stringify(info))
                            goToHome();
                        }
                    }).catch(() => {
                        goToLoginCenter();
                    })
                } else {
                    // setTimeout(function () {
                    // goToHome();
                    // }, 5000)
                    next({path: "/404"})
                }
            } else {
                next({path: "/404"})
            }
        }).catch(() => {
            next({path: "/404"})
        })
    } else {
        next()
    }
}
