<template>
    <div id="app">
        <Header/>
        <keep-alive>
            <router-view></router-view>
        </keep-alive>
        <Footer/>
    </div>
</template>

<script>
    import "../assets/js/jquery.i18n.properties.js";
    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue'
    export default {
        name: "Layout",
        components: {
            Header,
            Footer
        },
    }
</script>
<style scoped>
    @import "../assets/css/application.css";
</style>