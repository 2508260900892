import Vue from 'vue'
import Layout from './components/Layout.vue'
import router from './router'
import store from './store'
import common from './assets/js/common'


// Register it globally
Vue.config.productionTip = false;
Vue.prototype.common = common;

new Vue({
  router,
  store,
  render: h => h(Layout)
}).$mount('#app');
