function scrollDemo() {
    let hash = window.location.hash;
    if (hash) {
        hash = hash.replace("#", "");
        document.getElementById(hash).scrollIntoView({
            behavior: "smooth",  // 平滑过渡
            block: "start"  // 上边框与视窗顶部平齐。默认值
        });
    }
}

function showNormalModal(text, time) {
    if ($("#normal-modal").length == 0) {
        $("body").append('<div class="modal fade modal-tips modal-normal" id="normal-modal">' +
            '<div class="modal-dialog modal-sm">' +
            '<div class="modal-content">' +
            '<div class="modal-header">' +
            // '<h4 class="modal-tips-title">提示</h4>'+
            '</div>' +
            '<div class="modal-body">' +
            '<i class="fa fa-exclamation-circle fa-4x"></i>' +
            '<p class="modal-tips-word"></p>' +
            '</div>' +
            '<div class="modal-footer"></div>' +
            '</div>' +
            '</div>' +
            '</div>');
    }
    $('#normal-modal .modal-tips-word').text(text);
    $('#normal-modal').modal('show');
    setTimeout(function () {
        $('#normal-modal').modal('hide');
    }, (time || 1500));
}

module.exports  ={
    scrollDemo
    , showNormalModal
};